<script setup lang="ts">
import { computed, defineAsyncComponent, ref } from 'vue'
import { getServerState, useRoute, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { uppercaseFirstLetter } from '@/utils/helper-functions'
import type { FormattedFooterContent } from '@/format-content'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      isBroker: getServerState('isBroker'),
      isZmarta: getServerState('isZmarta'),
    }

// Components
const ZgIcon = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgIcon))

const content: FormattedFooterContent = getServerState('footer-content')!

const route = useRoute()
const minimalParam = ref<boolean>(route?.searchParams.get('minimal') === 'true')
const contact = computed(() => content?.contact)
const socialLinks = computed<Array<{ url: string, icon: string, label: string }>>(() => {
  const links: Record<string, any> = content?.socialLinks ?? {}

  return Object.keys(links).map((link) => ({
    url: links[link],
    icon: link?.replace(/([A-Z])/g, '-$1')?.toLowerCase(),
    label: uppercaseFirstLetter(link),
  }))
})
const showSocialLinks = computed(() => ctx?.isZmarta && (socialLinks.value ?? []).length && !minimalParam.value && !ctx?.isBroker)
</script>

<template>
  <div
    :class="['footer-contact', {
      'footer-contact--minimal': minimalParam
    }]"
  >
    <div class="contact" v-html="contact" />

    <div v-if="showSocialLinks" class="social-links">
      <a v-for="(link, index) in socialLinks" :key="index" :href="link.url" rel="noopener nofollow" target="_blank">
        <zg-icon v-if="link.icon" :icon="link.icon" />

        <span v-if="link.label">
          {{ link.label }}
        </span>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .footer-contact {
    display: flex;
    flex-direction: column;
    gap: rhythm(base);
    background-color: var(--brand-500);
    padding: rhythm(base);

    @include mq(medium) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: rhythm(base) rhythm(double);
    }

    .contact {
      display: grid;
      width: 100%;
      gap: rhythm();

      :deep(p),
      :deep(a) {
        @include type-body-s;

        color: var(--white);
        margin: 0;
      }
    }

    .social-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(16px);

      :deep(a) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: rem(8px);
        color: var(--white);
        border: none;
      }

      :deep(path) {
        fill: var(--white);
      }
    }

    :deep(a) {
      border-bottom: 1px solid;
    }
  }

  .footer-contact--minimal {
    background-color: var(--brand-100);

    @include mq(medium) {
      padding: rem(64px) rhythm(double) rhythm(base);
    }

    .contact {

      :deep(p),
      :deep(a) {
        color: var(--brand-500);
      }
    }
  }
</style>
